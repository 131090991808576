export function getAccessibleZones(zonesList, finishZone) {
  let zoneListArray = [];

  Object.entries(zonesList).forEach(([key, value]) => {
    value.forEach((val) => {
      if (val === finishZone) {
        zoneListArray.push(key);
      }
    });
  });

  let i = 0;
  let len = zoneListArray.length;

  while (i < len) {
    if (i < zoneListArray.length) {
      Object.entries(zonesList).forEach(([key, value]) => {
        value.forEach((val) => {
          zoneListArray.forEach((zone) => {
            if (val === zone) {
              zoneListArray.push(key);
              len = zoneListArray.length;

              i = 0;
            } else {
              i++;
            }
          });
        });
      });
    } else {
      i++;
    }
    break;
  }

  return zoneListArray;
}

export function isZoneReachableFromZone(zonePassList, startZone, endZone) {
  if (startZone === endZone) {
    return true;
  }

  return traverseOverZoneList(zonePassList, [startZone], endZone);
}

function traverseOverZoneList(zonePassList, visitedZones, endZone) {
  let currentZone = visitedZones[visitedZones.length - 1];
  let values = zonePassList[currentZone];

  if (!values) {
    return false;
  } else if (values.includes(endZone)) {
    return true;
  } else {
    for (let value of values) {
      if (!visitedZones.includes(value)) {
        // visitedZones.push(value);
        if (traverseOverZoneList(visitedZones, endZone)) {
          return true;
        }
      }
    }
  }
  return false;
}

export function getDegreeBetweenToCoordination(firstLocation, secondLocation) {
  const deltaX = secondLocation.longitude - firstLocation.longitude;
  const deltaY = secondLocation.latitude - firstLocation.latitude;
  let degree;

  if (deltaX === 0) {
    // Handle vertical alignment
    if (deltaY > 0) {
      degree = 0.0; // North
    } else {
      degree = 180.0; // South
    }
  } else {
    const ratio = deltaY / deltaX;
    const value = Math.atan(ratio);
    degree = (value / Math.PI) * 180;
    if (deltaX > 0) {
      // Eastward
      degree = 90 - degree;
    } else {
      // Westward
      degree = 270 - degree;
    }
  }

  if (degree < 0) {
    degree += 360;
  }
  if (degree > 360) {
    degree %= 360;
  }
  return degree;
}
