<template>
  <div v-if="navigation">
    <v-btn
      v-if="parking"
      style="top: 40vh; margin-left: -5px"
      elevation="7"
      fab
      small
      fixed
      top
      left
      @click="openParking"
      :class="hasCarInfo === true ? 'secondary--text' : 'grey--text'"
    >
      <v-icon> mdi-car </v-icon>
    </v-btn>
    <car-popup
      :carDialog="carDialog"
      :carPositionTitle="carPositionTitle"
      @closeCarPopup="closeCarPopup"
    />

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("carLocation") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col class="d-flex" cols="4" sm="4">
                <v-select
                  :items="title"
                  :label="$t('floor')"
                  dense
                  @change="floorSelected()"
                  v-model="selectedTitle"
                  item-text="title"
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="4" sm="4">
                <v-select
                  :items="letter"
                  :label="$t('letter')"
                  dense
                  v-model="selectedLetter"
                  @change="letterSelected()"
                ></v-select>
              </v-col>
              <v-col class="d-flex" cols="4" sm="4">
                <v-select
                  :items="number"
                  :label="$t('number')"
                  v-model="selectedNumber"
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hanndleClickCloseDialog">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="setCarLocation">
            {{ $t("settingBtn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { postAnalysesDataAsync } from "../services/services";
import CarPopup from "./CarPopup.vue";
export default {
  components: { CarPopup },
  //Left parking button component
  //Where is my car function
  name: "ParkingButton",
  data() {
    return {
      dialog: false,
      show: false,
      showIndex: null,
      title: [],
      letter: [],
      number: [],
      selectedLevel: null,
      selectedTitle: null,
      selectedLetter: null,
      selectedNumber: null,
      carPositionTitle: null,
      carDialog: false,
      hasCarInfo: localStorage.getItem("carPosition") ? true : false,
    };
  },
  methods: {
    closeCarPopup() {
      this.carDialog = false;
      this.hasCarInfo = false;
    },
    async setCarLocation() {
      await postAnalysesDataAsync({
        eventKey: "CAR_BUTTON",

        eventData: {
          timestamp: Math.floor(new Date().getTime() / 1000),

          //  String -> “saveManually”, “showMyCar”, “goToMyCar, “closeWithCar”, “closeWithoutCar”
          action: "saveManually",
        },
      });
      let title = `${this.selectedLetter}${this.selectedNumber}`;
      let carPosition = `${this.selectedTitle}-${this.selectedLetter}${this.selectedNumber}`;
      localStorage.setItem("carPosition", carPosition);
      if ((this.selectedTitle, this.selectedLetter, this.selectedNumber)) {
        this.dialog = false;
      }

      const foundParkingSpots = this.$store.getters.parkingSpots.filter(
        (p) => p.location.properties.floorLevel === this.selectedLevel
      );
      foundParkingSpots.forEach((f) => {
        let splitted = f.title.split("-");

        let found = splitted.indexOf(title);
        if (found > -1) {
          localStorage.setItem("carPositionId", f.id);
          localStorage.setItem(
            "carPositionFloor",
            f.location.properties.floorLevel
          );

          localStorage.setItem(
            "carPositionCoordinates",
            JSON.stringify(f.location.geometry.coordinates)
          );
        }
      });

      this.hasCarInfo = true;
    },
    async hanndleClickCloseDialog() {
      this.dialog = false;
      await postAnalysesDataAsync({
        eventKey: "CAR_BUTTON",

        eventData: {
          timestamp: Math.floor(new Date().getTime() / 1000),

          //  String -> “saveManually”, “showMyCar”, “goToMyCar, “closeWithCar”, “closeWithoutCar”
          action: "closeWithoutCar",
        },
      });
    },
    floorSelected() {
      this.$store.getters.parkingLabels.forEach((e) => {
        if (e.title === this.selectedTitle) {
          this.letter = Object.keys(e.labels);
          this.selectedLevel = e.level;
        }
      });
    },
    letterSelected() {
      this.$store.getters.parkingLabels.forEach((e) => {
        if (e.title === this.selectedTitle) {
          Object.keys(e.labels).forEach((a) => {
            if (a === this.selectedLetter) {
              this.number = e.labels[a];
            }
          });
        }
      });
    },
    openParking() {
      if (localStorage.getItem("carPosition")) {
        //if user car location set before
        this.carPositionTitle = localStorage.getItem("carPosition");
        this.carDialog = true;
      } else {
        //if user car location not selected before
        this.dialog = true;
      }
    },
  },

  computed: {
    //if there is a parking option show parking button
    parking() {
      const paramsParkingConfig = this.$store.getters.urlParamsConfigs.parking;
      const dataParkingConfig = this.$store.getters.placeConfig.parking;

      if (paramsParkingConfig === false && dataParkingConfig === true)
        return false;
      else return dataParkingConfig;
    },
    navigation() {
      const paramsNavigationConfig =
        this.$store.getters.urlParamsConfigs.navigation;
      const dataNavigationConfig = this.$store.getters.placeConfig.navigation;

      if (paramsNavigationConfig === false && dataNavigationConfig === true)
        return false;
      return dataNavigationConfig;
    },
  },

  created() {
    this.$store.getters.parkingLabels.forEach((e) => {
      this.title.push({ title: e.title, level: e.level });
    });
  },

  mounted() {
    if (localStorage.getItem("carPosition")) {
      //if user car location set before
      this.hasCarInfo = true;
    } else {
      //if user car location not selected before
      this.hasCarInfo = false;
    }
  },
};
</script>
