<template>
  <v-row justify="center">
    <v-dialog
      :value="openWarningDialog"
      v-if="$store.getters.pureRoute.length !== 0"
      persistent
      max-width="450"
      content-class="dialog-content-round"
    >
      <v-card class="pt-4 pb-4">
        <p
          class="text-h6 text-center margin-bottom-8 d-print-block font-weight-bold"
        >
          {{ $t("information") }}
        </p>
        <v-card-text class="v-card-text">
          <div
            class="d-flex flex-row justify-space-between align-item-center grey lighten-3 pa-3 rounded-lg mb-3"
          >
            <div>
              <h3 class="text-left">
                {{ $t("routeWarningDestination") }}
              </h3>
            </div>

            <div>
              <span v-if="$store.getters.routeInfo.length > 0">
                {{ truncatedDestinationTitle }}</span
              >
            </div>
          </div>

          <div
            class="d-flex flex-row justify-space-between align-item-center grey lighten-3 pa-3 rounded-lg mb-3"
          >
            <div>
              <h3 class="text-left">
                {{ $t("estimated") }}
              </h3>
            </div>

            <div v-if="$store.getters.pureRoute.data.eta < 1">
              <span>
                1
                {{ $t("minute") }}
              </span>
            </div>
            <div v-else>
              <span>
                {{ Math.round($store.getters.pureRoute.data.eta) }}
                {{ $t("minute") }}
              </span>
            </div>
          </div>

          <div
            class="d-flex flex-row justify-space-between align-item-center grey lighten-3 pa-3 rounded-lg mb-3"
          >
            <div>
              <h3 class="text-left">
                {{ $t("distance") }}
              </h3>
            </div>

            <div>
              <span
                >{{ Math.floor($store.getters.pureRoute.data.dist) }}
                {{ $t("meter") }}</span
              >
            </div>
          </div>

          <!-- nothing -->
          <div v-if="routeWarningData.length">
            <h3 class="text-center" style="color: red; font-weight: bold">
              {{ $t("caution") }}
            </h3>
          </div>
          <div v-for="item in routeWarningData" :key="item.id">
            <div>
              <div v-html="item.warningTitle"></div>
            </div>
            <v-divider
              v-if="routeWarningData.length > 1"
              class="mt-2"
            ></v-divider>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="flex flex-row justify-center">
            <v-btn
              color="secondary"
              class="rounded-lg"
              @click="handleCloseDialog"
              large
            >
              {{ $t("accept") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  //Left top corner gift button component
  //Show campaing info
  name: "RouteWarning",
  props: ["routeInfo"],
  data() {
    return {
      routeWarningData: [],
      openWarningDialog: false,
    };
  },

  methods: {
    handleCloseDialog() {
      this.openWarningDialog = false;
    },
  },

  computed: {
    truncatedDestinationTitle() {
      const title =
        this.$store.getters.routeInfo[this.$store.getters.routeInfo.length - 1]
          .title;
      return title.length > 20 ? title.substring(0, 20) + "..." : title;
    },
  },
  mounted() {
    if (this.$store.getters.routeInfo.length > 1) {
      this.openWarningDialog = true;
    } else {
      this.openWarningDialog = false;
    }
    this.$store.getters.stores.forEach((place) => {
      if (
        place.properties &&
        this.$store.getters.pureRoute.data.route.length > 0
      ) {
        this.$store.getters.pureRoute.data.route.forEach((route) => {
          if (place.location.geometry.coordinates) {
            if (place.id === route[0]) {
              if (place.properties.restrictionWarning) {
                this.$store.dispatch(
                  "routeWarning",
                  place.properties.restrictionWarning
                );

                this.routeWarningData.push({
                  warningTitle: place.properties.restrictionWarning,
                  placeName: place.title,
                  id: place.id,
                });
              }
            }
          }
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.snackbar-content p {
  margin-bottom: 0px !important;
  text-align: left;
}

.modal-warning-title {
  font-weight: normal;
  text-align: left !important;
  width: 210px;
}
.v-btn {
  text-transform: none;
}

.v-card-text {
  padding: 0px 20px !important;
}
</style>
