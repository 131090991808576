<template>
  <div class="global-loader" v-if="$store.getters.placesLoading && hasParams">
    <div class="global-wrapper">
      <h3>{{ $t("loading") }}</h3>
      <div><BeatLoader color="#fff" /></div>
    </div>
    <div class="blur"></div>
  </div>
</template>

<script>
import BeatLoader from "vue-spinner/src/BeatLoader.vue";

export default {
  //Top right corner category button component
  name: "GlobalLoader",
  components: {
    BeatLoader,
  },
  data() {
    return {
      categoriesStore: [],
      hasParams: false,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    if (
      urlParams.has("startStoreId") ||
      urlParams.has("endStoreId") ||
      urlParams.has("nodeId") ||
      urlParams.has("endNodeId") ||
      urlParams.has("multiStoreIds") ||
      urlParams.has("focusStoreId") ||
      urlParams.has("focusLat") ||
      urlParams.has("focusLon")
    ) {
      this.hasParams = true;
    }
  },
};
</script>

<style scoped>
.global-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.global-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 300px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  background-color: rgba(41, 118, 210, 0.95);
  z-index: 9999999;
}
.blur {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 99999;
  backdrop-filter: blur(1.5px);
  background-color: rgba(255, 255, 255, 0.1);
}
h3 {
  color: #fff;
  margin-bottom: 5px;
}
</style>
