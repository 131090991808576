<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    x="0"
    y="0"
    viewBox="0 0 60.123 60.123"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M57.124 51.893H16.92a3 3 0 1 1 0-6h40.203a3 3 0 0 1 .001 6zM57.124 33.062H16.92a3 3 0 1 1 0-6h40.203a3 3 0 0 1 .001 6zM57.124 14.231H16.92a3 3 0 1 1 0-6h40.203a3 3 0 0 1 .001 6z"
        :fill="color"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <circle
        cx="4.029"
        cy="11.463"
        r="4.029"
        :fill="color"
        opacity="1"
        data-original="#000000"
        class=""
      ></circle>
      <circle
        cx="4.029"
        cy="30.062"
        r="4.029"
        :fill="color"
        opacity="1"
        data-original="#000000"
        class=""
      ></circle>
      <circle
        cx="4.029"
        cy="48.661"
        r="4.029"
        :fill="color"
        opacity="1"
        data-original="#000000"
        class=""
      ></circle>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CategoryIcon",
  props: {
    width: {
      type: String,
      default: "25",
    },
    height: {
      type: String,
      default: "25",
    },
    color: {
      type: String,
      default: "#1976d2",
    },
  },
};
</script>

<style scoped></style>
