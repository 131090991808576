<template>
  <div>
    <div class="px-4 primary">
      <v-text-field
        :label="$t('destination')"
        solo
        v-model="currentLocation"
        @input="handleChangeSearchStartLocationInput"
        clearable
        prepend-inner-icon="mdi-flag"
        @focus="destinationTextFieldFocus"
      >
      </v-text-field>
    </div>
    <div class="px-4 mb-2 primary">
      <v-text-field
        prepend-inner-icon="mdi-map-marker"
        solo
        disabled
        :value="
          !$store.getters.title
            ? $store.getters.facilitiesTitle
            : $store.getters.title
        "
      >
      </v-text-field>
    </div>
    <v-list>
      <v-virtual-scroll
        bench="0"
        :items="stores"
        :item-height="80"
        height="400"
        class="mx-auto"
      >
        <template v-slot:default="{ item }">
          <v-list-item
            @click="handleClickOnEachListItem(item.id)"
            :key="item.id"
          >
            <v-list-item-content>
              <div class="d-flex flex-row">
                <v-list-item-subtitle>
                  <div
                    class="d-flex flex-row align-item-center justify-content-start"
                  >
                    <h3 class="fw-bold text-black">
                      {{ item.title }}
                    </h3>
                  </div>
                </v-list-item-subtitle>
              </div>
              <div
                class="d-flex flex-row justify-content-center mt-3 align-item-center"
              >
                <v-list-item-subtitle>
                  <div
                    class="d-flex flex-row align-item-center justify-content-start"
                  >
                    <span>{{
                      renderFloorData(item.location.properties.floorLevel)
                    }}</span>
                    {{ item.category ? "," : "" }}

                    <span class="ml-1">
                      {{
                        item.category
                          ? Object.prototype.hasOwnProperty.call(
                              item.category,
                              "subcategory"
                            )
                            ? item.category.subcategory.name
                              ? item.category.subcategory.name
                              : item.category.name
                            : item.category.name
                          : ""
                      }}
                    </span>
                    <!-- <span class="ml-1">{{ item.distance }}</span> -->
                  </div>
                </v-list-item-subtitle>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="item.id + 1"></v-divider>
        </template>
      </v-virtual-scroll>
    </v-list>

    <SimpleKeyboard
      @onChange="handleChangeSearchStartLocationInput"
      :input="currentLocation"
      @onToggleKeyboard="onToggleKeyboard"
      :showKeyboard="showKeyboard"
    />
  </div>
</template>

<script>
import { getAccessibleZones } from "../utilities/mapUtilities";
import { turkishToEnglish } from "../utilities/turkishTonEnglish";
import SimpleKeyboard from "./SimpleKeyboard";

export default {
  name: "OriginBox",
  props: ["type", "handleCloseOriginDialog"],
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      store: null,
      stores: [],
      storeList: [],
      currentLocation: "",
      searchType: "search",
      showKeyboard: false,
    };
  },
  watch: {
    store(val) {
      this.routeStore(val, this.$store.getters.id);
    },
  },
  methods: {
    destinationTextFieldFocus() {
      this.showKeyboard = true;
    },

    onToggleKeyboard(toggle) {
      this.showKeyboard = toggle;
    },

    handleCloseDialog() {
      this.$emit("handleCloseOriginDialog");
      this.currentLocation = "";
    },
    renderFloorData(floorLevel) {
      let floorData = "";

      this.$store.getters.floors.forEach((item) => {
        if (item.level === floorLevel) {
          floorData = item.title;
        }
      });

      return floorData;
    },
    handleChangeSearchStartLocationInput(e) {
      this.currentLocation = e;

      const zoneList = this.$store.getters.place.data.properties.zonePassList;
      const destinationZoneId = this.$store.getters.shopInfo.zoneId;

      let accessableDestinationZones = [];

      if (destinationZoneId) {
        accessableDestinationZones = getAccessibleZones(
          zoneList,
          destinationZoneId
        );

        accessableDestinationZones.push(destinationZoneId);
      }

      if (this.currentLocation) {
        const filterdBySearchArr = this.$store.getters.stores.filter(
          (store) => {
            const tagArray = Object.prototype.hasOwnProperty.call(store, "tags")
              ? store.tags
              : [];
            const subcategoryName = store.category?.subcategory
              ? turkishToEnglish(store.category.subcategory.name.toLowerCase())
                ? turkishToEnglish(
                    store.category.subcategory.name.toLowerCase()
                  )
                : ""
              : "";
            let storeTitle = turkishToEnglish(store.title.toLowerCase());
            let storeCategory = turkishToEnglish(
              store.category?.name ? store.category.name.toLowerCase() : ""
            );
            const searchedCurrentLocation = turkishToEnglish(e.toLowerCase());

            return (
              (store.navigation.properties.isVisibleOnList === true &&
                storeTitle.toLowerCase().includes(searchedCurrentLocation)) ||
              storeCategory.toLowerCase().includes(searchedCurrentLocation) ||
              tagArray.some((tag) => {
                let tagConvertor = turkishToEnglish(tag.toLowerCase());
                return tagConvertor.includes(searchedCurrentLocation);
              }) ||
              (subcategoryName
                .toLowerCase()
                .includes(searchedCurrentLocation) &&
                store.navigation.properties.isVisibleOnList === true &&
                this.$store.getters.id !== store.id)
            );
          }
        );

        let tempArrayStore = [];
        filterdBySearchArr.map((item) => {
          if (accessableDestinationZones.length) {
            accessableDestinationZones.forEach((zone) => {
              if (Object.prototype.hasOwnProperty.call(item, "properties")) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    item.properties,
                    "zoneId"
                  )
                ) {
                  if (zone === item.properties.zoneId) {
                    tempArrayStore.push(item);
                  }
                }
              }
            });
          } else {
            tempArrayStore.push(item);
          }
        });

        this.stores = tempArrayStore;
      } else {
        let tempArrayStore = [];

        this.$store.getters.stores.forEach((element) => {
          if (
            element.navigation.properties.isVisibleOnList === true &&
            this.$store.getters.id !== element.id
          ) {
            if (accessableDestinationZones.length) {
              accessableDestinationZones.forEach((zone) => {
                if (
                  Object.prototype.hasOwnProperty.call(element, "properties")
                ) {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      element.properties,
                      "zoneId"
                    )
                  ) {
                    if (zone === element.properties.zoneId) {
                      tempArrayStore.push(element);
                    }
                  }
                }
              });
            } else {
              tempArrayStore.push(element);
            }
          }
        });
        // sort stores alphabetically by title
        this.stores = tempArrayStore;

        this.sortStores(this.stores);
      }
    },
    handleClickOnEachListItem(val) {
      this.routeStore(val, this.$store.getters.id);
    },
    clearSearch() {
      this.$store.dispatch("reset");
    },
    //Gets route info and shows direction
    async routeStore(start, finish) {
      //if user search near by WC finish position is WC

      if (this.type !== "search") {
        this.clearSearch();
        this.stores.forEach((element) => {
          if (element.id === start) {
            let route = {
              floor: element.location.properties.floorLevel,
              center: element.location.geometry.coordinates,
              startPoint: element.id,
              endPoint: this.type,
            };
            this.$store.dispatch("getRoute", route);
            this.$store.dispatch("destinationDialog", false);
            this.$store.dispatch("showClusters", false);
            this.currentLocation = "";
          }
        });

        this.stores = [];
        this.handleChangeSearchStartLocationInput("");
        //if user search near by WC finish position is Elevator
      } else {
        this.clearSearch();
        this.stores.forEach((element) => {
          if (element.id === start) {
            let route = {
              floor: element.location.properties.floorLevel,
              center: element.location.geometry.coordinates,
              startPoint: element.id,
              endPoint: finish,
            };
            this.$store.dispatch("getRoute", route);
            this.currentLocation = "";
            this.$store.dispatch("showClusters", false);
          }
        });
        this.stores = [];
        this.handleChangeSearchStartLocationInput("");
      }
      this.handleCloseDialog();
    },
    //sorts stores array alphabetically by title
    sortStores(arr) {
      const sortedAlphabetically = arr.sort(function (a, b) {
        var textA = a.title.toUpperCase();
        var textB = b.title.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      sortedAlphabetically.forEach((element) => {
        this.stores.push(element);
        this.storeList.push(element);
      });

      //if user has default startpoint (comes from QR Code) do not ask user location and call routeStore function
      if (this.$store.getters.defaultUserLocation) {
        this.stores.forEach((element) => {
          if (element.id === this.$store.getters.defaultUserLocation) {
            setTimeout(() => {
              this.routeStore(element.id, this.$store.getters.id);
            }, 1);
          }
        });
      }
    },
  },
  //when component is mounted get all store items and filter shop and visible ones
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    const endPoint = urlParams.get("endStoreId");
    const hasStartStoreId = urlParams.has("startStoreId");
    const hasEndStoreId = urlParams.has("endStoreId");

    const endNodeIdPoint = urlParams.get("endNodeId");
    const hasStartNodeIdPoint = urlParams.has("nodeId");
    const hasEndNodeIdPoint = urlParams.has("endNodeId");

    const zoneList = this.$store.getters.place.data.properties.zonePassList;
    let destinationZoneId = this.$store.getters.shopInfo.zoneId;
    let accessableDestinationZones = [];
    this.stores = [];

    if (hasEndStoreId === true && hasStartStoreId === false) {
      this.$store.getters.stores.forEach((element) => {
        if (element.filters.store_ids.includes(endPoint)) {
          destinationZoneId = element.properties
            ? element.properties.zoneId
              ? element.properties.zoneId
              : ""
            : "";
        }
      });
    } else if (hasStartStoreId === false && hasEndStoreId === false) {
      destinationZoneId = this.$store.getters.shopInfo.zoneId;
    } else if (hasEndNodeIdPoint === true && hasStartNodeIdPoint === false) {
      this.$store.getters.stores.forEach((element) => {
        if (element.id === endNodeIdPoint) {
          destinationZoneId = element.properties
            ? element.properties.zoneId
              ? element.properties.zoneId
              : ""
            : "";
        }
      });
    } else if (hasStartNodeIdPoint === false && hasEndNodeIdPoint === false) {
      destinationZoneId = this.$store.getters.shopInfo.zoneId;
    }

    if (destinationZoneId) {
      accessableDestinationZones = getAccessibleZones(
        zoneList,
        destinationZoneId
      );
      accessableDestinationZones.push(destinationZoneId);
    }
    let tempArrayStore = [];

    this.$store.getters.stores.forEach((element) => {
      if (
        element.navigation.properties.isVisibleOnList === true &&
        this.$store.getters.id !== element.id
      ) {
        if (accessableDestinationZones.length) {
          accessableDestinationZones.forEach((zone) => {
            if (Object.prototype.hasOwnProperty.call(element, "properties")) {
              if (
                Object.prototype.hasOwnProperty.call(
                  element.properties,
                  "zoneId"
                )
              ) {
                if (zone === element.properties.zoneId) {
                  tempArrayStore.push(element);
                }
              }
            }
          });
        } else {
          tempArrayStore.push(element);
        }
      }
    });
    // sort stores alphabetically by title
    this.stores = tempArrayStore;

    this.sortStores(this.stores);
    // this.stores = this.$store.getters.stores;
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  overflow-x: hidden !important;
}
</style>
