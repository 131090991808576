<template>
  <div v-if="$store.getters.floors.length > 1">
    <div v-if="!loading" :class="['floor-button-wrapper', positionClass]">
      <div
        v-if="
          (placeConfig.floorDropdown &&
            placeConfig.floorDropdown.expandable === false) ||
          placeConfig.floorDropdown === undefined
        "
      >
        <v-btn
          class="drop-down-btn d-flex flex-row justify-content-end align-item-center"
          elevation="0"
          :ripple="false"
          :hover="false"
          @click="handleOpenFloorDropdown"
        >
          <span class="mr-2 icon font-weight-bold">
            {{ getFloorText }}
          </span>
          <div
            class="d-flex flex-row align-item-center"
            style="position: relative"
          >
            <div
              v-show="
                moreResultSnackBar &&
                $store.getters.openFloorsDropDown === false
              "
              class="show-cluster-dot"
            ></div>
            <img :src="layerSvg" class="mr-2 p-2" />
          </div>
        </v-btn>
      </div>

      <div v-if="$store.getters.openFloorsDropDown === true">
        <v-expand-transition>
          <v-list :class="['floor-list', floorListPositionClass]">
            <!-- scroll up -->
            <v-list-item
              v-if="showScrollButtons"
              class="position-absolute scroll-up-btn"
            >
              <v-btn
                @click="floorsListScrollBtn('up')"
                block
                size="small"
                height="25px"
                elevation="1"
              >
                <v-icon color="primary">mdi-chevron-up</v-icon>
              </v-btn>
            </v-list-item>

            <div
              ref="scrollableFloorsList"
              :style="{
                'max-height': floorsListHeight + 'px',
                overflow: 'hidden',
                padding: '10px 0',
              }"
            >
              <v-list-item
                v-for="floor in this.$store.getters.floors"
                :key="floor.level"
              >
                <div
                  v-show="
                    moreResultSnackBar &&
                    $store.getters.clustersList.some(
                      (cluster) =>
                        cluster.location.properties.floorLevel === floor.level
                    ) === true
                  "
                  class="show-cluster-dot"
                ></div>
                <v-btn
                  :color="
                    parseInt(getFloorLevel) === floor.level
                      ? 'accent   text-white'
                      : ''
                  "
                  block
                  min-width="40px"
                  height="40px"
                  size="small"
                  elevation="1"
                  class="pa-1 text-capitalize mb-3 rounded-lg floor-btn d-flex flex-row justify-content-center align-item-center"
                  @click="changeFloor2(floor)"
                >
                  {{ floor.title }}
                </v-btn>
              </v-list-item>
            </div>

            <!-- scroll down  -->
            <v-list-item
              v-if="showScrollButtons"
              class="position-absolute scroll-down-btn"
            >
              <v-btn
                @click="floorsListScrollBtn('down')"
                block
                height="25px"
                size="small"
                elevation="1"
              >
                <v-icon color="primary">mdi-chevron-down</v-icon>
              </v-btn>
            </v-list-item>

            <v-list-item
              class="mt-10"
              v-if="
                this.placeConfig.disabilityButton &&
                this.placeConfig.disabilityButton.position === 'top-right'
              "
              style="float: right"
            >
              <SettingsButton />
            </v-list-item>
          </v-list>
        </v-expand-transition>
      </div>
    </div>

    <div v-else class="skleton-bg">
      <v-btn disabled elevation="2"> {{ $t("loading") }}...</v-btn>
    </div>
  </div>
</template>

<script>
import SettingsButton from "@/components/SettingsButton.vue";

export default {
  //Right bottom change floor buttons
  name: "FloorButtons",
  props: ["routeInfo", "showClusters", "loading"],
  components: {
    SettingsButton,
  },
  data() {
    return {
      floors: [],
      layerSvg: require("../assets/img/layerPrimary.svg"),
      moreResultSnackBar: false,
      showScrollButtons: false,
      floorsListHeight: 0,
      floorsListRef: null,
    };
  },
  methods: {
    floorsListScrollBtn(direction) {
      if (!this.$refs.scrollableFloorsList) return;
      this.$refs.scrollableFloorsList.scrollTop +=
        direction === "up" ? -50 : 50;
    },

    updateFloorsListHeight() {
      this.floorsListHeight = window.innerHeight - 200;
      this.checkScrollBtnVisibility();
    },

    checkScrollBtnVisibility() {
      // Ensure this runs after the DOM is updated
      this.$nextTick(() => {
        if (
          this.$refs.scrollableFloorsList &&
          this.$store.getters.openFloorsDropDown
        ) {
          this.showScrollButtons =
            this.$refs.scrollableFloorsList.scrollHeight >
            this.$refs.scrollableFloorsList.clientHeight;
        } else {
          this.showScrollButtons = false;
        }
      });
    },

    handleOpenFloorDropdown() {
      const open = this.$store.getters.openFloorsDropDown;
      this.$store.dispatch("openFloorsDropDown", !open);

      // Ensure visibility checks after toggling
      this.$nextTick(() => {
        this.updateFloorsListHeight();
        this.checkScrollBtnVisibility();
      });
    },

    changeFloor(val) {
      if (this.routeInfo.length < 1) {
        this.$store.dispatch("changeFloor", val);
      }
    },

    changeFloor2(floor) {
      this.$store.dispatch("changeFloor2", floor.level);
      if (
        this.placeConfig.floorDropdown &&
        this.placeConfig.floorDropdown.expandable === true
      ) {
        this.$store.dispatch("openFloorsDropDown", true);
      } else {
        this.$store.dispatch("openFloorsDropDown", false);
      }

      this.$nextTick(() => {
        this.updateFloorsListHeight();
        this.checkScrollBtnVisibility();
      });
    },
  },

  computed: {
    getFloorText() {
      //Gets floor text
      if (this.$store.getters.focusCoordsParamsInfo) {
        return this.$store.getters.focusCoordsParamsInfo.title;
      } else if (this.$store.getters.coordinates.length > 0)
        return this.$store.getters.coordinates[this.$store.getters.floor].title;
      else {
        return "";
      }
    },
    getFloorLevel() {
      //Gets floor text

      if (this.$store.getters.focusCoordsParamsInfo) {
        return this.$store.getters.focusCoordsParamsInfo.level;
      } else if (this.$store.getters.coordinates.length > 0)
        return this.$store.getters.coordinates[this.$store.getters.floor].level;
      else return null;
    },
    placeConfig() {
      return this.$store.getters.placeConfig;
    },
    positionClass() {
      if (
        this.placeConfig.floorDropdown &&
        this.placeConfig.floorDropdown.position
      ) {
        const position = this.placeConfig.floorDropdown.position;
        return {
          "position-top": position === "top",
          "position-center": position === "center",
          "position-bottom": position === "bottom",
        };
      } else {
        return "position-bottom";
      }
    },
    floorListPositionClass() {
      if (
        this.placeConfig.floorDropdown &&
        this.placeConfig.floorDropdown.position
      ) {
        const position = this.placeConfig.floorDropdown.position;
        return {
          "floor-list-position-top": position === "top",
          "floor-list-position-bottom":
            position === "bottom" || position === "center",
        };
      } else {
        return {
          "floor-list-position-bottom": true,
        };
      }
    },
  },
  mounted() {
    this.updateFloorsListHeight(); // Initial calculation
    this.$nextTick(() => {
      this.checkScrollBtnVisibility(); // Ensure visibility calculation runs after DOM updates
    });
    window.addEventListener("resize", this.updateFloorsListHeight); // Recalculate on window resize
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateFloorsListHeight);
  },
  watch: {
    showClusters: function () {
      if (this.$store.getters.showClusters == true) {
        const currentFloorInfo =
          this.$store.getters.floors[this.$store.getters.floor];

        const triggerMoreResultSnackBar = this.$store.getters.clustersList.some(
          (cluster) =>
            cluster.location.properties.floorLevel !== currentFloorInfo.level
        );
        this.moreResultSnackBar = triggerMoreResultSnackBar;
      } else {
        this.moreResultSnackBar = false;
      }
    },

    "$store.getters.openFloorsDropDown": function () {
      this.checkScrollBtnVisibility();
    },
  },
};
</script>

<style lang="scss" scoped>
.floor-button-wrapper {
  position: fixed;
  z-index: 99;
}

.position-top {
  top: 20px;
  right: 20px;
}

.position-center {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.position-bottom {
  bottom: 20px;
  right: 20px;
}
.floor-btn {
  background-color: transparent;
  min-width: 140px;
}
.floor-btn .floor-btn-level {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.floor-btn-active {
  background-color: #1776d1 !important;
}
.floor-btn .floor-btn-title {
  background-color: #fff;
  padding: 2px 3px;
  border-radius: 4px;
  font-size: 14px;
}
.drop-down-btn {
  padding: 25px 5px !important;
  color: #000;
  min-width: 200px;
  text-transform: unset;
}
.skleton-bg {
  background-color: #fff;
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.show-cluster-dot {
  background-color: #f05a5c;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  position: absolute;
  top: 2px;
  right: 18px;
  z-index: 99;
}
.floor-list {
  z-index: 9999;
  position: absolute;
  /* top: 60px;
  right: -20px; */
  background-color: transparent;
}
.floor-list-position-bottom {
  bottom: 60px;
  right: -20px;
}
.floor-list-position-top {
  top: 60px;
  right: -14px;
}

.v-list-item {
  min-height: unset !important;
  justify-content: flex-end;
}
.scroll-up-btn {
  top: -10px;
  z-index: 99999;
  transform: translateX(50%);
}
.scroll-down-btn {
  bottom: -0px;
  z-index: 99999;
  transform: translateX(50%);
}
</style>
