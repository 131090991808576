<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="['elevator-icon', className]"
  >
    <g clip-path="url(#clip0_2063_1062)">
      <path
        d="M15.4167 5H4.58333C2.05667 5 0 7.05583 0 9.58333V15.4167C0 17.9442 2.05667 20 4.58333 20H15.4167C17.9433 20 20 17.9442 20 15.4167V9.58333C20 7.05583 17.9433 5 15.4167 5ZM17.5 15.4167C17.5 16.0967 17.1725 16.7017 16.6667 17.0825C16.6667 15.4725 15.3608 14.1667 13.75 14.1667C12.1392 14.1667 10.8333 15.4725 10.8333 17.0833V17.5H9.16667V17.0833C9.16667 15.4725 7.86083 14.1667 6.25 14.1667C4.63917 14.1667 3.33417 15.4717 3.33333 17.0825C2.8275 16.7017 2.5 16.0967 2.5 15.4167V9.58333C2.5 8.435 3.43417 7.5 4.58333 7.5H15.4167C16.5658 7.5 17.5 8.435 17.5 9.58333V15.4167ZM8.33333 11.25C8.33333 12.4008 7.40083 13.3333 6.25 13.3333C5.09917 13.3333 4.16667 12.4008 4.16667 11.25C4.16667 10.0992 5.09917 9.16667 6.25 9.16667C7.40083 9.16667 8.33333 10.0992 8.33333 11.25ZM15.8333 11.25C15.8333 12.4008 14.9008 13.3333 13.75 13.3333C12.5992 13.3333 11.6667 12.4008 11.6667 11.25C11.6667 10.0992 12.5992 9.16667 13.75 9.16667C14.9008 9.16667 15.8333 10.0992 15.8333 11.25ZM3.75167 2.23083L5.79333 0.189167C6.04583 -0.0625 6.45417 -0.0625 6.70667 0.189167L8.74917 2.23167C9.15583 2.63833 8.8675 3.33417 8.2925 3.33417H4.20833C3.63333 3.33417 3.345 2.63833 3.75167 2.23083ZM11.2517 1.10167C10.845 0.695 11.1333 0 11.7083 0H15.7925C16.3675 0 16.6558 0.695 16.2492 1.10167L14.2067 3.14417C13.9542 3.39667 13.5458 3.39667 13.2933 3.14417L11.2517 1.10167Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2063_1062">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ElevatorIcon",
  props: {
    width: {
      type: String,
      default: "25",
    },
    height: {
      type: String,
      default: "25",
    },
    className: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.elevator-icon {
  fill: currentColor;
}
</style>
