export const correctSuffixForWord = (word) => {
  if (word.length === 0) {
    return "";
  }

  // Define vowels for vowel harmony
  const backVowels = new Set(["a", "ı", "o", "u"]);
  const frontVowels = new Set(["e", "i", "ö", "ü"]);

  // Define consonants for consonant softening
  const softeningConsonants = { p: "b", ç: "c", t: "d", k: "g" };

  // Find the last vowel in the word for vowel harmony
  let lastVowel = null;
  for (let i = word.length - 1; i >= 0; i--) {
    const character = word.charAt(i);
    if (backVowels.has(character) || frontVowels.has(character)) {
      lastVowel = character;
      break;
    }
  }

  // Apply consonant softening if necessary
  const lastChar = word.charAt(word.length - 1);
  const softenedLastChar = softeningConsonants[lastChar] || lastChar;
  /*   const baseWord =
    softenedLastChar === lastChar ? word : word.slice(0, -1)  */
  const baseWord = word;

  // Determine the correct suffix based on vowel harmony
  let suffix;
  if (backVowels.has(lastVowel)) {
    suffix = softenedLastChar === lastChar ? "dan" : "tan";
  } else if (frontVowels.has(lastVowel)) {
    suffix = softenedLastChar === lastChar ? "den" : "ten";
  } else {
    // If no vowel is found, return a default suffix (optional)
    suffix = "dan";
  }

  return `${baseWord}'${suffix}`;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
