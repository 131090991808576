<template>
  <div v-if="campaign && campaignData.length > 0 && navigation">
    <v-btn
      v-if="campaign && campaignData.length > 0"
      style="color: #ff1493"
      elevation="7"
      fab
      small
      fixed
      top
      left
      @click="openCampaing"
      class="mt-1"
    >
      <v-icon> mdi-bullhorn-outline </v-icon>
    </v-btn>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialog"
      style="z-index: 1001"
    >
      <template v-slot:default="dialog">
        <v-card>
          <div
            v-for="(campaing, index) in campaignData"
            :key="campaing.id"
            class="mb-4"
            ref="category"
          >
            <v-card class="mx-auto" max-width="344" :ref="index">
              <v-card-subtitle class="text-left">
                {{ $t("campaingEndDate") }}
                {{ campaing.endDate.slice(8, 10) }}-{{
                  campaing.endDate.slice(5, 7)
                }}-{{ campaing.endDate.slice(0, 4) }}
              </v-card-subtitle>
              <v-img :src="campaing.image" height="200px">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-card-title style="word-break: normal">
                {{ campaing.title }}
              </v-card-title>
              <v-divider class="mx-4"></v-divider>

              <v-card-subtitle class="text-left">
                {{ campaing.description.slice(0, 200) }}...
              </v-card-subtitle>
              <v-card-actions>
                <v-btn text color="teal accent-4" @click="showIndex = index">
                  {{ $t("detail") }}
                </v-btn>
              </v-card-actions>
              <v-expand-transition>
                <v-card
                  v-show="showIndex == index"
                  class="transition-fast-in-fast-out v-card--reveal"
                >
                  <v-card-text class="pb-0">
                    <p>
                      {{ campaing.description }}
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-0">
                    <v-btn text color="teal accent-4" @click="showIndex = null">
                      {{ $t("close") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false"> {{ $t("close") }}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  //Left top corner gift button component
  //Show campaing info
  name: "GiftButton",
  data() {
    return {
      dialog: false,
      show: false,
      showIndex: null,
    };
  },
  methods: {
    openCampaing() {
      this.dialog = true;
    },
  },
  computed: {
    //if there is a campaing show campaign button
    campaign() {
      const paramsCampaignConfig =
        this.$store.getters.urlParamsConfigs.campaign;
      const dataCampaignConfig = this.$store.getters.placeConfig.campaign;

      if (paramsCampaignConfig === false && dataCampaignConfig === true)
        return false;
      else return dataCampaignConfig;
    },
    campaignData() {
      return this.$store.getters.campaign;
    },
    navigation() {
      const paramsNavigationConfig =
        this.$store.getters.urlParamsConfigs.navigation;
      const dataNavigationConfig = this.$store.getters.placeConfig.navigation;

      if (paramsNavigationConfig === false && dataNavigationConfig === true)
        return false;
      return dataNavigationConfig;
    },
  },
  async mounted() {
    if (this.campaign === true) await this.$store.dispatch("getCampaign");
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
