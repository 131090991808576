import { render, staticRenderFns } from "./ElevatorIcon.vue?vue&type=template&id=2ee72560&scoped=true"
import script from "./ElevatorIcon.vue?vue&type=script&lang=js"
export * from "./ElevatorIcon.vue?vue&type=script&lang=js"
import style0 from "./ElevatorIcon.vue?vue&type=style&index=0&id=2ee72560&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee72560",
  null
  
)

export default component.exports