<template>
  <div
    style="
      position: sticky;
      bottom: 0;
      right: 0px;
      left: 0px;
      width: 100%;
      z-index: 99999999;
    "
    v-if="$store.getters.urlParamsConfigs.virtualKeyboard"
  >
    <!-- Toggle Button -->
    <div
      v-show="!showKeyboard"
      class="position-absolute open-keyboard elevation"
    >
      <v-btn icon dark @click="onToggleKeyboard(true)">
        <v-icon color="#00000096" size="35">mdi-keyboard</v-icon>
      </v-btn>
    </div>

    <!-- Close Button and Keyboard -->
    <div v-show="showKeyboard">
      <div class="position-absolute close-keyboard elevation">
        <v-btn icon dark @click="onToggleKeyboard(false)">
          <v-icon color="#00000096">mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <!-- Keyboard container -->
      <div :class="keyboardClass"></div>
    </div>
  </div>
</template>
<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    input: {
      type: String,
    },
    layout: {
      type: String,
      default: "default", // default or turkish
    },
    showKeyboard: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: this.getLayout(),
    });
  },
  methods: {
    onToggleKeyboard(toggle) {
      this.$emit("onToggleKeyboard", toggle);
    },
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      if (button === "{shift}" || button === "{lock}") this.handleShift();
      if (button === "{lang}") this.handleLanguageChange();

      if (button === "{enter}") {
        this.onToggleKeyboard(false);
        this.$emit("onEnter");
      }
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle =
        currentLayout === "default" || currentLayout === "turkish"
          ? "shift"
          : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    handleLanguageChange() {
      let currentLayout = this.keyboard.options.layout;
      let layoutToggle =
        currentLayout === this.layouts.default
          ? this.layouts.turkish
          : this.layouts.default;

      this.keyboard.setOptions({
        layout: layoutToggle,
      });
    },
    getLayout() {
      // Define the default and Turkish layouts
      const localStorageLanguage = localStorage.getItem("language");
      const layouts = {
        en: {
          default: [
            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
            "{tab} q w e r t y u i o p [ ] \\",
            "{lock} a s d f g h j k l ; ' {enter}",
            "{shift} z x c v b n m , . / {shift}",
            ".com @ {space}",
          ],
          shift: [
            "~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}",
            "{tab} Q W E R T Y U I O P { } |",
            '{lock} A S D F G H J K L : " {enter}',
            "{shift} Z X C V B N M &lt; &gt; ? {shift}",
            ".com @ {space}",
          ],
        },
        tr: {
          default: [
            '" 1 2 3 4 5 6 7 8 9 0 * - # {bksp}',
            "{tab} q w e r t y u ı o p ğ ü [ ]",
            "{lock} a s d f g h j k l ş i , {enter}",
            "{shift} < z x c v b n m ö ç . | $ € {shift}",
            ".com @ {space}",
          ],
          shift: [
            "é ! ' ^ + % & / ( ) = ? _ ~ {bksp}",
            "{tab} Q W E R T Y U I O P Ğ Ü { }",
            "{lock} A S D F G H J K L Ş İ ; {enter}",
            "{shift} > Z X C V B N M Ö Ç : \\ ` ´ {shift}",
            ".com @ {space}",
          ],
        },
        ar: {
          default: [
            "\u0630 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
            "{tab} \u0636 \u0635 \u062B \u0642 \u0641 \u063A \u0639 \u0647 \u062E \u062D \u062C \u062F \\",
            "{lock} \u0634 \u0633 \u064A \u0628 \u0644 \u0627 \u062A \u0646 \u0645 \u0643 \u0637 {enter}",
            "{shift} \u0626 \u0621 \u0624 \u0631 \u0644\u0627 \u0649 \u0629 \u0648 \u0632 \u0638 {shift}",
            ".com @ {space}",
          ],
          shift: [
            "\u0651 ! @ # $ % ^ & * ) ( _ + {bksp}",
            "{tab} \u064E \u064B \u064F \u064C \u0644\u0625 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |",
            '{lock} \u0650 \u064D ] [ \u0644\u0623 \u0623 \u0640 \u060C / : " {enter}',
            "{shift} ~ \u0652 } { \u0644\u0622 \u0622 \u2019 , . \u061F {shift}",
            ".com @ {space}",
          ],
        },
        hrv: {
          default: [
            "` 1 2 3 4 5 6 7 8 9 0 ' = {bksp}",
            "{tab} q w e r t z u i o p š đ",
            "{lock} a s d f g h j k l č ć {enter}",
            "{shift} y x c v b n m , . - {shift}",
            ".com @ {space}",
          ],
          shift: [
            "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
            "{tab} Q W E R T Z U I O P Š Đ",
            "{lock} A S D F G H J K L Č Ć {enter}",
            "{shift} Y X C V B N M < > _ {shift}",
            ".com @ {space}",
          ],
        },
      };

      return layouts[localStorageLanguage] || layouts.en;
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
    layout(newLayout) {
      this.keyboard.setOptions({
        layout: this.getLayout(newLayout),
      });
    },
  },
};
</script>

<style scoped></style>
